import { FC, useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { ApiResponse } from '../../models/ApiResponse';
import { FormConfig } from '../../models/Form';
import { FormType, formTypeOptions } from '../../models/FormTypes';
import { currentClientAtom, currentTenantIdAtom } from '../../recoil/atoms/Clients';
import DateUtils from '../../utils/DateUtils';
import DataRow from '../../components/shared/data-grid/DataRow';
import SortableHeading from '../../components/shared/data-grid/SortableHeading';
import CheckIcon from '../../components/shared/icon/CheckIcon';
import ActivityIcon from '../../components/shared/icon/ActivityIcon';
import InfoIcon from '../../components/shared/icon/InfoIcon';
import Loader from '../../components/shared/Loader';
import StatusTag, { StatusVariant } from '../../components/shared/tags/StatusTag';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import { useTranslation } from 'react-i18next';
import LanguageUtils from '../../utils/LanguageUtils';
import ConfirmationModal from '../../components/shared/modal/variants/ConfirmationModal';
import { ModalContext } from '../../contexts/ModalContext';
import DocumentSpaceIcon from '../../components/shared/icon/DocumentSpaceIcon';

type FormTemplateListProps = {
  formsPaged: ApiResponse<FormConfig[]>;
  onArchive: (formId: string) => void;
  onRestore: (formId: string) => void;
  onLoadMore?: (pageNumber: number) => void;
  sortBy: string;
  onSort?: (expression: string) => void;
  isLoading: boolean;
};

const FormTemplateList: FC<FormTemplateListProps> = (props) => {
  const { formsPaged, onArchive, onRestore, onLoadMore, onSort, sortBy, isLoading } = props;
  const [sortExpressions, setSortExpressions] = useState<Record<string, string>>({});
  const [showRestoreConfim, setShowRestoreConfirm] = useState(false);
  const [showArchiveConfim, setShowArchiveConfirm] = useState(false);
  const [selectedForm, setSelectedForm] = useState<FormConfig | null>(null);
  const currentPage = formsPaged.pageNumber || 0;
  const totalPages = formsPaged.totalPages || 0;
  const currentClient = useRecoilValue(currentClientAtom);
  const currentTenantId = useRecoilValue(currentTenantIdAtom);
  const { t } = useTranslation(['organisation', 'common']);

  const updateSortExpression = (field: string, expression: string) => {
    const expressions = { ...sortExpressions, [field]: expression };
    setSortExpressions(expressions);

    const fullExpression = Object.values(expressions).filter((x) => x !== expression);
    fullExpression.unshift(expression); // move to first

    onSort && onSort(fullExpression.join(','));
  };

  useEffect(() => {
    let expressions = {};
    for (const expression of sortBy.split(',')) {
      expressions = { ...expressions, [expression.replace(/[-+]/g, '')]: expression };
    }
    setSortExpressions(expressions);
  }, [sortBy]);

  const formTypes = formTypeOptions(t);
  const getFormTypeOptionText = useCallback(
    (formType: (typeof FormType)[keyof typeof FormType]) => {
      return formTypes.find((type) => type.value === formType)?.text as string;
    },
    [formTypes],
  );

  const [lastElementRef] = useInfiniteScroll(currentPage < totalPages ? () => onLoadMore && onLoadMore(currentPage + 1) : null, isLoading);

  return (
    <div>
      <div className="flex w-full items-end border-2 border-transparent pl-4">
        <div className="w-4/12">
          <div className="w-12">{/* SPACER */}</div>
          <SortableHeading
            title={t('organisation:activities.list.heading.name')}
            onSort={updateSortExpression}
            expression={sortExpressions['title'] ?? '+title'}
          />
        </div>
        <div className="w-2/12">{t('organisation:activities.list.heading.type')}</div>
        <div className="w-2/12">
          <SortableHeading
            title={t('organisation:activities.list.heading.version')}
            onSort={updateSortExpression}
            expression={sortExpressions['version'] ?? '+version'}
          />
        </div>
        <div className="w-2/12">
          <SortableHeading
            title={t('organisation:activities.list.heading.modified')}
            onSort={updateSortExpression}
            expression={sortExpressions['modifiedUtc'] ?? '+modifiedUtc'}
          />
        </div>
        <div className="w-2/12">{t('organisation:activities.list.heading.status')}</div>
        <div className="mr-2 w-10">{/* SPACER */}</div>
        <div className="w-8">{/* SPACER */}</div>
      </div>
      {formsPaged.data.map((form, i) => {
        const isLast = formsPaged.data.length === i + 1;
        return (
          <div key={form.id} ref={isLast ? lastElementRef : undefined}>
            <DataRow
              data-cy={`form-${form.id}`}
              key={form.id}
              url={
                !currentClient?.id
                  ? `/builder/${currentTenantId}/form-builder/${form.id}/${form.version}`
                  : `/clients/${currentClient.id}/form-builder/${form.id}/${form.version}`
              }
              contextMenuItems={[
                {
                  title: form.archivedUtc ? t('organisation:activities.list.menu.restore') : t('organisation:activities.list.menu.archive'),
                  onClick: () => {
                    setSelectedForm(form);
                    if (form.archivedUtc) {
                      setShowRestoreConfirm(true);
                    } else {
                      setShowArchiveConfirm(true);
                    }
                  },
                },
              ]}
            >
              <div className="flex w-4/12 items-center text-left">
                {form.type === FormType.Document ? (
                  <DocumentSpaceIcon className="bg-gray-1 mr-4 h-8 w-8 flex-shrink-0 rounded-full p-2 text-white" />
                ) : form.type === FormType.Asset ? (
                  <ActivityIcon className="bg-gray-1 mr-4 h-8 w-8 flex-shrink-0 rounded-full p-2 text-white" />
                ) : (
                  <CheckIcon className="bg-gray-1 mr-4 h-8 w-8 flex-shrink-0 rounded-full p-2 text-white" />
                )}
                <div>
                  <div className="text-color-2 font-medium">{LanguageUtils.getTranslation('title', form.translations)}</div>
                  <div className="text-color-3 text-dpm-12">{form.code}</div>
                </div>
              </div>
              <div className="w-2/12">{getFormTypeOptionText(form.type)}</div>
              <div className="w-2/12">v{form.version}</div>
              <div className="w-2/12">{DateUtils.formatDateTime(new Date(form.modifiedUtc))}</div>
              <div className="flex w-2/12 justify-items-start pr-2">
                {form.archivedUtc ? (
                  <StatusTag data-cy="archived" text={t('organisation:activities.filter.archived')} statusType={StatusVariant.RED} />
                ) : (
                  <StatusTag data-cy="active" text={t('organisation:activities.filter.active')} statusType={StatusVariant.GREEN} />
                )}
              </div>
            </DataRow>
          </div>
        );
      })}
      {isLoading && (
        <div className="flex flex-col items-center py-6">
          <Loader size={16} centered={false} />
        </div>
      )}
      {formsPaged.data.length === 0 && (
        <div data-cy="task-list-empty" className="flex h-full justify-center ">
          <div className="mt-36 text-center">
            <InfoIcon className="bg-primary-1 text-primary-1 my-2 h-16 w-16 rounded-full bg-opacity-10 p-4" />
            <Heading size={HeadingSize.H3} className="my-4">
              {t('organisation:forms-defaults.list.empty.heading')}
            </Heading>
            <div className="text-dpm-20">{t('organisation:forms-defaults.list.empty.sub-heading')}</div>
          </div>
        </div>
      )}

      {/* archive confirm modal */}
      <ModalContext.Provider value={{ open: showArchiveConfim, onClose: () => setShowArchiveConfirm(false) }}>
        <ConfirmationModal
          title={t('organisation:activities.modals.archive.title')}
          description={t('organisation:activities.modals.archive.description', {
            title: selectedForm ? LanguageUtils.getTranslation('title', selectedForm.translations) : '',
          })}
          cancelText={t('organisation:activities.modals.archive.cancel')}
          confirmText={t('organisation:activities.modals.archive.confirm')}
          onConfirm={() => {
            if (selectedForm) {
              onArchive(selectedForm?.id);
              setShowArchiveConfirm(false);
            }
          }}
          onCancel={() => setShowArchiveConfirm(false)}
          alt
        />
      </ModalContext.Provider>
      {/* restore modal */}
      <ModalContext.Provider value={{ open: showRestoreConfim, onClose: () => setShowRestoreConfirm(false) }}>
        <ConfirmationModal
          title={t('organisation:activities.modals.restore.title')}
          description={t('organisation:activities.modals.restore.description', {
            title: selectedForm ? LanguageUtils.getTranslation('title', selectedForm.translations) : '',
          })}
          cancelText={t('organisation:activities.modals.restore.cancel')}
          confirmText={t('organisation:activities.modals.restore.confirm')}
          onConfirm={() => {
            if (selectedForm) {
              onRestore(selectedForm?.id);
              setShowRestoreConfirm(false);
            }
          }}
          onCancel={() => setShowRestoreConfirm(false)}
        />
      </ModalContext.Provider>
    </div>
  );
};

export default FormTemplateList;
